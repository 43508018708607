document.addEventListener('DOMContentLoaded', function() {

    var FILES = new FormData();

    init();

    //
    // [data-room-form=""] -- FORM ROOM with ID
    // [data-mega-form] -- FORM with ID
    // [data-mega-form-url="/"] - for all this 2 URL same attr
    //

    //
    // [data-clone-all-room]
    // [data-clear-all-room]
    // [data-delete-all-room]
    //

    //
    // Form Step Blocks 1,2,3,4
    // [data-form-block]
    //

    //
    // Form Step Buttons Blocks 1,2,3,4
    // [data-button-next-block]
    //

    //
    // Form Step Left Menu Buttons 1,2,3,4
    // [data-button-goto-block]
    //

    //
    // Main Room Block
    // [data-room-block]
    //
    // Room Subblock
    // [data-room-subblock]
    //
    // Room Menu Icon
    // [data-room-menu-icon]
    //
    // Room Menu Popup
    // [data-room-menu-popup]
    //
    // Room Main CheckBox
    // [data-room-block-main-checkbox]
    //
    // Room Top CheckBox
    // [data-room-block-top-checkbox]
    //
    // [data-room-main-block] - MAIN BLOCK!
    //
    
    //
    // Data Subform Main CheckBox
    // [data-subform-checkbox]
    //
    // Subform Block
    // [data-subform-block]
    //
    // Main Subform block
    // [data-subform-parent]
    //
    // Subform block Height
    // [data-subform-block-height]
    //
    //
    // [data-height-calculation-master]
    // [data-height-calculation-subblock]
    //
    
    //
    // File Upload Input
    // [data-file-input]
    //
    // File Upload Button
    // [data-file-button]
    //
    // File Upload Block
    // [data-file-block]
    //
    // File Imput Text
    // [data-file-text]
    //
    // Uploaded Files Block
    // [data-uploaded-files-block]
    //
    // Uploaded Files Items To CLONE with Class .clone
    //
    // [data-file-select=""] - select with ID
    // [data-uploaded-files-items-select] - work with select
    //

    //
    // if Checked Disable input/select
    // [data-disable-checkbox] - to check
    // [data-disable-input] - to disable
    // [data-disable-block] - block
    //

    //
    // Special hide/show checkbox with ID
    // add/remove class hide
    // [data-special-checkbox-input="ID"]
    // [data-special-checkbox-field="ID"]
    // [data-special-checkbox-not-input="ID"]
    //

    //
    // Select Count to Clone with ID
    // [data-select-clone=""] - select
    // [data-select-clone-block=""] - to clone block
    // [data-select-clone-item=""] - clone item value
    // [data-select-item=""] - cloned items
    //

    //
    // [data-select-no-checkbox-select=""] -- select
    // [data-select-no-checkbox=""] -- select value
    // [data-select-no-checkbox-tohide=""] -- this block hide
    //

    //
    // To add different_room
    // [data-add-different-room-block]
    // [data-add-different-room-input]
    // [data-add-different-room-button]
    //

    //
    // Show/hide block 4 room select
    // [data-block4-room-select]
    //

    function init() {

        $('body').on('click', '[data-burger-menu]', function(e) {
            e.preventDefault();

            var $this = $(this);

            var $menuBlock = $this.closest('.mq-action');
            var $menu = $menuBlock.find('.mq-menu');

            if (!$this.hasClass('open')) {
                $this.addClass('open');
                $menu.addClass('open');
                $menuBlock.addClass('open');
            } else {
                $this.removeClass('open');
                $menu.removeClass('open');
                $menuBlock.removeClass('open');
            }

        });

        $('body').on('change', '[data-select-no-checkbox-select]', function() {
            var $this = $(this);

            var attr = $this.attr('data-select-no-checkbox-select');

            if ($this.find('option[data-select-no-checkbox]').prop('selected') == true) {
                $this.parent().find('[data-select-no-checkbox-tohide="' + attr + '"]').addClass('hide');
            } else {
                $this.parent().find('[data-select-no-checkbox-tohide="' + attr + '"]').removeClass('hide');
            }
        });


        $('body').on('change','[name="wardrobe_isolation_type"]', function() {
            var $this = $(this);
            specialShowHide($this);
        });

        $('body').on('change','[name="master_bedroom_wardrobe_isolation_type"]', function() {
            var $this = $(this);
            specialShowHide($this);
        });

        $('body').on('change','[name="guests_bedroom_wardrobe_isolation_type"]', function() {
            var $this = $(this);
            specialShowHide($this);
        });

        $('body').on('change','[name="bathroom_type"]', function() {
            var $this = $(this);
            specialShowHide($this);
        });

        $('body').on('change','[name="master_bedroom_bathroom_type"]', function() {
            var $this = $(this);
            specialShowHide($this);
        });

        $('body').on('change','[name="guests_bedroom_bathroom_type"]', function() {
            var $this = $(this);
            specialShowHide($this);
        });

        $('body').on('change','[name="child_bedroom_bathroom_type"]', function() {
            var $this = $(this);
            specialShowHide($this);
        });


        $('body').on('click', '[data-uploaded-files-close]', function() {
            var $this = $(this);

            $this.closest('[data-uploaded-files-items]').remove();
        });

        $('body').on('change','[data-file-input]', function(e) {
            var $this = $(this);

            var $thisMainBlock = $this.closest('[data-file-block]');
            var thisComment = $thisMainBlock.find('[data-file-text]').val();

            var thisId = $this.attr('id');
            var $thisUploadedBlock = $('[data-uploaded-files-block="' + thisId + '"]');

            var $thisSelect = $('[data-file-select="' + thisId + '"]');

            if ($this.val() != '') {

                var file_data = $this.prop('files')[0];
                FILES.append('file[]', file_data);

                var fileName = $this.val().replace(/C:\\fakepath\\/i, '');

                if ($thisUploadedBlock.length > 0) {
                    var $toCloneBlock = $thisUploadedBlock.find('[data-uploaded-files-items].clone');
                    var $clonedBlock = $toCloneBlock.clone();

                    $thisMainBlock.find('[data-file-text]').val('');
                    $clonedBlock.removeClass('clone');
                    $clonedBlock.find('[data-uploaded-files-items-comment]').text(thisComment + ' (' + fileName + ')');
                    $clonedBlock.find('input').val(fileName);
                    $clonedBlock.find('[data-uploaded-files-items-comment-input]').val(thisComment);


                    if ($thisSelect) {
                        var thisSelectValue = $thisSelect.find('select  option:selected').text();
                        $clonedBlock.find('[data-uploaded-files-items-select]').text(thisSelectValue);
                        $clonedBlock.find('[data-room-name-stuff]').val(thisSelectValue);
                    }

                    if ($clonedBlock.find('img')) {
                        var imgFile = e.originalEvent.srcElement.files[0];
                        var reader = new FileReader();
                        reader.onloadend = function(e) {
                            $clonedBlock.find('img').attr('src', reader.result);
                        };
                        reader.readAsDataURL(imgFile);
                    }

                    $thisUploadedBlock.append($clonedBlock);
                    fileUpload();
                }
                $this.val('');
            }
        });

        updatePage();

        $('[data-special-checkbox-input]').each(function() {
            var $this = $(this);
            $this.change();
        });

        function getMacOrderId() {
            var maxId = 0;
            $('[data-order-id]').each(function() {
                var id = parseInt($(this).attr('data-order-id'));

                if (id > maxId) {
                    maxId = id;
                }
            });
            return maxId + 1;
        }

        $('body').on('click','[data-room-block-top-checkbox]', function() {
            var $this = $(this);
            var thisBlockName = $this.attr('data-room-block-top-checkbox');
            var $thisBlock = $('[data-room-block="' + thisBlockName + '"]');

            $thisBlock.find('[data-order-id]').attr('data-order-id', getMacOrderId());

            if ($this.prop('checked')) {
                revealRoomBlock($thisBlock);
            } else {
                hideRoomBlock($thisBlock);
            }
        });

        $('body').on('click','[data-button-next-block]', function(e) {
            e.preventDefault();

            var $this = $(this);
            var nextBlockId = $this.attr('data-button-next-block');

            var formId = $this.closest('[data-mega-form]').attr('data-mega-form');

            if (formValidationFunction(formId)) {
                saveCurrentForm(parseInt(formId));
                goToBlock(nextBlockId);
            }
        });

        $('body').on('click','[data-button-goto-block]', function(e) {
            e.preventDefault();
            var $this = $(this);
            var nextBlockId = $this.attr('data-button-goto-block');
            saveAll();
            goToBlock(nextBlockId);
        });

        $('body').on('click','[data-room-menu-icon]', function() {
            var $this = $(this);
            $this.find('[data-room-menu-popup]').addClass('active');
        });


        $('body').click(function(e) {
            if (!e.target.closest('[data-room-menu-icon]')) {
                $('[data-room-menu-popup]').removeClass('active');
            }
        });

        $(document).click(function(e) {
            if (!e.target.closest('[data-room-menu-icon]')) {
                $('[data-room-menu-popup]').removeClass('active');
            }
            if (!e.target.closest('[data-modal-item]') && !e.target.closest('[data-modal-open]')) {
                $('[data-modal-close]').trigger('click');
            }
        });

        $('body').on('click','[data-clone-all-room]', function() {
            var $this = $(this);

            var thisAttr = $this.find('[data-room-block]');

            var $mainBlockToClone = $this.closest('[data-room-main-block]');
            var $clonedBlock = $mainBlockToClone.clone();

            $clonedBlock.find('[data-order-id]').attr('data-order-id', getMacOrderId());

            var clonedId = parseInt($clonedBlock.find('[data-dont-clear]').val());
            var newId = findMaxByName($clonedBlock.find('[data-dont-clear]'));
            newId = parseInt(newId) + 1;
            $clonedBlock.find('[data-dont-clear]').val((newId));

            $clonedBlock.find('input').each(function() {
                var $clonedInput = $(this);
                var clonedInputId = $clonedInput.attr('id');
                $clonedInput.attr('id', clonedInputId + '_' + newId);
            });

            $clonedBlock.find('select').each(function() {
                var $clonedSelect = $(this);
                var clonedSelectId = $clonedSelect.attr('id');
                $clonedSelect.attr('id', clonedSelectId + '_' + newId);
            });

            $clonedBlock.find('textarea').each(function() {
                var $clonedTextarea = $(this);
                var clonedTextareaId = $clonedTextarea.attr('id');
                $clonedTextarea.attr('id', clonedTextareaId + '_' + newId);
            });

            $clonedBlock.find('[data-select-clone]').each(function() {
                var $clonedSelectclone = $(this);
                var clonedSelectcloneAttr = $clonedSelectclone.attr('data-select-clone');
                $clonedSelectclone.attr('data-select-clone', clonedSelectcloneAttr + '_' + newId);
            });

            $clonedBlock.find('[data-select-clone-block]').each(function() {
                var $clonedSelectclone = $(this);
                var clonedSelectcloneAttr = $clonedSelectclone.attr('data-select-clone-block');
                $clonedSelectclone.attr('data-select-clone-block', clonedSelectcloneAttr + '_' + newId);
            });

            $clonedBlock.find('[data-select-clone-item]').each(function() {
                var $clonedSelectclone = $(this);
                var clonedSelectcloneAttr = $clonedSelectclone.attr('data-select-clone-item');
                $clonedSelectclone.attr('data-select-clone-item', clonedSelectcloneAttr + '_' + newId);
            });

            $clonedBlock.find('label').each(function() {
                var $clonedLabel = $(this);

                var clonedLabelId = $clonedLabel.attr('for');
                $clonedLabel.attr('for', clonedLabelId + '_' + newId);
            });

            $clonedBlock.find('[data-uploaded-files-block]').attr('data-uploaded-files-block', ($clonedBlock.find('[data-uploaded-files-block]').attr('data-uploaded-files-block') + '_' + newId));

            $clonedBlock.find('[data-cloned-item-number]').text(newId);

            $clonedBlock.insertAfter($mainBlockToClone);

            $clonedBlock.find('[data-clear-all-room]').trigger('click');
        });

        $('body').on('click','[data-delete-all-room]', function() {
            var $this = $(this);

            var $mainBlockToClone = $this.closest('[data-room-main-block]');
            var Id = $mainBlockToClone.find('[data-dont-clear]').val();

            if (Id != 1) {
                $mainBlockToClone.remove();
            }
        });

        $('body').on('click','[data-clear-all-room]', function() {
            var $this = $(this);

            var $mainBlockToClone = $this.closest('[data-room-main-block]');
            $mainBlockToClone.find('input').each(function() {
                var $input = $(this);
                if ($input.attr('name') == 'new_room_name') {

                } else {
                    if (typeof $input.attr('data-dont-clear') === "undefined" || $input.attr('data-dont-clear') === false) {
                        if ($input.attr('type') == 'checkbox' || $input.attr('type') == 'radio') {
                            if (typeof $input.attr('data-disable-checkbox') === "undefined" || $input.attr('data-disable-checkbox') === false) {
                                $input.prop('checked', false);
                            }
                        } else {
                            $input.val('');
                        }
                    }
                }
            });

            $mainBlockToClone.find('textarea').each(function() {
                var $input = $(this);
                if (typeof $input.attr('data-dont-clear') === "undefined" || $input.attr('data-dont-clear') === false) {
                    $input.val('');
                }
            });

            $mainBlockToClone.find('select').each(function() {
                var $input = $(this);
                if (typeof $input.attr('data-dont-clear') === "undefined" || $input.attr('data-dont-clear') === false) {
                    $input.find('option:first').prop('selected', true);
                }
                $input.change();
            });

            $mainBlockToClone.find('[data-uploaded-files-items]').each(function() {
                var $fileBlock = $(this);
                if (!$fileBlock.hasClass('clone')) {
                    $fileBlock.remove();
                }
            });

            updatePage();
        });

        $('body').on('click', '[data-add-different-room-button]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var $thisBlock = $this.closest('[data-add-different-room-block]');
            var $thisInput = $thisBlock.find('[data-add-different-room-input]');

            var newRoomName = $thisInput.val();

            if ($('[data-room-block-top-checkbox="different_room"]').prop('checked') == false) {

                $('[data-room-block-top-checkbox="different_room"]').trigger('click');
                $('[data-room-block="different_room"]').find('[data-room-block-main-checkbox]').trigger('click');

                $('[data-room-block="different_room"]').find('[name="new_room_name"]').val(newRoomName);
                $('[data-room-block="different_room"]').find('[data-new-room-name]').text(newRoomName);
            } else {
                var thisAttr = 'different_room';

                var $mainBlockToClone = $('[data-room-block="' + thisAttr + '"]').last().closest('[data-room-main-block]');
                var $clonedBlock = $mainBlockToClone.clone();

                var clonedId = parseInt($clonedBlock.find('[data-dont-clear]').val());
                var newId = findMaxByName($clonedBlock.find('[data-dont-clear]'));
                newId = parseInt(newId) + 1;
                $clonedBlock.find('[data-dont-clear]').val((newId));

                $clonedBlock.find('input').each(function() {
                    var $clonedInput = $(this);

                    var clonedInputId = $clonedInput.attr('id');

                    $clonedInput.attr('id', clonedInputId + '_' + newId);

                });

                $clonedBlock.find('label').each(function() {
                    var $clonedLabel = $(this);

                    var clonedLabelId = $clonedLabel.attr('for');
                    $clonedLabel.attr('for', clonedLabelId + '_' + newId);
                });

                $clonedBlock.find('[data-uploaded-files-block]').attr('data-uploaded-files-block', ($clonedBlock.find('[data-uploaded-files-block]').attr('data-uploaded-files-block') + '_' + newId));

                $clonedBlock.insertAfter($mainBlockToClone);

                $clonedBlock.find('[data-clear-all-room]').trigger('click');

                $clonedBlock.find('[name="new_room_name"]').val(newRoomName);
                $clonedBlock.find('[data-new-room-name]').text(newRoomName);

                $clonedBlock.find('[data-room-block-main-checkbox]').trigger('click');
            }

            $thisInput.val('');

        });

        $('body').on('click', '[data-new-form-button]', function (e) {
            e.preventDefault();

            var $this = $(this);

            var url = $this.attr('data-new-form-button');
            var toGoUrl = $this.attr('data-new-form-url-to-go');

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                success: function (response, err) {
                    console.log(response);
                    console.log(err);
                    if (response.status == 'ok') {
                        window.location = toGoUrl + '?&new_form_id=' + response.new_form_id;
                    }
                    else {
                        console.log('Error');
                        console.log(response.msg);
                    }
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('click', '[data-mega-button-save]', function(e) {
            e.preventDefault();

            saveAll();
        });

        $('body').on('click', '[data-modal-close]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var $modalBlock = $('[data-modal]');
            var $modalItems = $modalBlock.find('[data-modal-item]');

            $modalBlock.removeClass('show');
            $modalItems.addClass('hide');
        });

        $('body').on('click', '[data-edit-user-data]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var $userForm = $('[data-edit-user-from]');
            var url = $this.attr('data-edit-user-data-url');

            var formOk = true;

            if ($userForm.find('[name="pass"]').val() == '') {
                formOk = false;
                $userForm.find('[name="pass"]').addClass('error');
            } else {
                $userForm.find('[name="pass"]').removeClass('error');
            }
            if ($userForm.find('[name="repeat_pass"]').val() == '') {
                formOk = false;
                $userForm.find('[name="repeat_pass"]').addClass('error');
            } else {
                $userForm.find('[name="repeat_pass"]').removeClass('error');
            }
            if ($userForm.find('[name="pass"]').val() != $userForm.find('[name="repeat_pass"]').val()) {
                formOk = false;
                $userForm.find('[name="pass"]').addClass('error');
                $userForm.find('[name="repeat_pass"]').addClass('error');
            } else {
                $userForm.find('[name="pass"]').removeClass('error');
                $userForm.find('[name="repeat_pass"]').removeClass('error');
            }

            if (formOk) {

                $.ajax({
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    data: $userForm.serialize(),
                    success: function (response, err) {
                        console.log(response);
                        console.log(err);
                        if (response.status == 'ok') {
                            location.href = location.href;
                        }
                        else {
                            console.log('Error');
                            console.log(response.msg);
                        }
                    },
                    error: function (response, err) {
                        console.log(response);
                        console.log(err);
                        console.log('Fatal Error');
                    }
                });
            }

        });

        $('body').on('click', '[data-modal-open]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var url = $this.attr('data-modal-url');
            var data = $this.attr('data-modal-data');

            var type = $this.attr('data-modal-open');

            var $modalBlock = $('[data-modal]');
            var $myModel = $modalBlock.find('[data-modal-item="' + type + '"]');

            if (type == 'edit-user') {
                $.ajax({
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    data: 'name=' + data,
                    success: function (response, err) {
                        console.log(err);
                        $modalBlock.addClass('show');
                        $myModel.removeClass('hide');
                        $myModel.find('[name="role"]').val(response.role);
                        $myModel.find('[name="name"]').val(response.name);
                        $myModel.find('[data-user-name-here]').text(response.name);
                        $myModel.find('[name="full_name"]').val(response.full_name);
                        $myModel.find('[name="phone"]').val(response.phone);
                        $myModel.find('[name="email"]').val(response.email);
                    },
                    error: function(response, err) {
                        console.log(response);
                        console.log(err);
                        console.log('Fatal Error');
                    }
                });
            }

            if (type == 'delete') {
                $modalBlock.addClass('show');
                $myModel.removeClass('hide');
                $myModel.find('[data-mng-delete-user]').attr('data-mng-delete-user-url', url);
                $myModel.find('[data-mng-delete-user]').attr('data-mng-delete-user-login', data);
            }

        });

        $('body').on('click', '[data-form-delete-button]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var url = $this.attr('data-form-delete-url');
            var formId = $this.attr('data-form-delete-id');

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: 'form_id=' + formId,
                success: function (response, err) {
                    console.log(response);
                    console.log(err);
                    if (response.status == 'ok') {
                        $this.parent().remove();
                    }
                    else {
                        console.log('Error');
                        console.log(response.msg);
                    }
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('click', '[data-form-undelete-button]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var url = $this.attr('data-form-undelete-url');
            var formId = $this.attr('data-form-undelete-id');

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: 'form_id=' + formId,
                success: function (response, err) {
                    console.log(response);
                    console.log(err);
                    if (response.status == 'ok') {
                        location.href = location.href;
                    }
                    else {
                        console.log('Error');
                        console.log(response.msg);
                    }
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('click', '[data-form-send-b4-button]', function(e) {
            e.preventDefault();
            var $this = $(this);

            saveAll();

            var formId = getNewFormId();
            var url = $this.attr('data-form-send-b4-button-url');

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: 'form_id=' + formId,
                success: function (response, err) {
                    console.log(response);
                    console.log(err);
                    if (response.status == 'ok') {
                        location.href = '/form';
                    }
                    else {
                        console.log('Error');
                        console.log(response.msg);
                    }
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('click', '[data-sent-form]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var formId = $this.attr('data-sent-form');
            var url = $this.attr('data-sent-form-url');

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: 'form_id=' + formId,
                success: function (response, err) {
                    console.log(response);
                    console.log(err);
                    if (response.status == 'ok') {
                        location.href = location.href;
                    }
                    else {
                        console.log('Error');
                        console.log(response.msg);
                    }
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('click', '[data-autologin-forms-button]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var url = $this.attr('data-autologin-forms-url');
            var $select = $('[data-autologin-forms-select]');
            var $input = $('[data-autologin-forms-input]');

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: 'name=' + $select.val(),
                success: function (response, err) {
                    console.log(response);
                    console.log(err);
                    if (response.send == 1) {
                        console.log(':)');
                    } else if (response.status == 'ok') {
                        $input.val(location.origin + '/' + $('html').attr('lang') + response.link);
                    }
                    else {
                        console.log('Error');
                        console.log(response.msg);
                    }
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('click', '[data-save-user-data]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var url = $this.attr('data-save-user-data-url');

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: $this.closest('form').serialize(),
                success: function (response, err) {
                    console.log(response);
                    console.log(err);
                    if (response.status == 'ok') {
                        location.href = location.href;
                    }
                    else {
                        console.log('Error');
                        console.log(response.msg);
                    }
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('click', '[data-mng-change-user-pass]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var url = $this.attr('data-mng-change-user-pass-url');
            var login = $this.attr('data-mng-change-user-login');

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: $this.closest('form').serialize() + '&name=' + login,
                success: function (response, err) {
                    console.log(response);
                    console.log(err);
                    if (response.status == 'ok') {
                        $this.closest('form').find('[name="pass"]').val('');
                    }
                    else {
                        console.log('Error');
                        console.log(response.msg);
                    }
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('click', '[data-mng-delete-user]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var url = $this.attr('data-mng-delete-user-url');
            var login = $this.attr('data-mng-delete-user-login');

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: 'name=' + login,
                success: function (response, err) {
                    console.log(response);
                    console.log(err);
                    if (response.status == 'ok') {
                        $('[data-modal-close]').trigger('click');
                        $('[data-modal-data="' + login + '"]').closest('tr').remove();
                    }
                    else {
                        console.log('Error');
                        console.log(response.msg);
                    }
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('change', '[data-user-get-data-select]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var url = $this.attr('data-user-get-data-url');
            var form_id = getNewFormId();

            var $nameField = $('[data-user-get-data-name]');
            var $emailField = $('[data-user-get-data-email]');
            var $phoneField = $('[data-user-get-data-phone]');

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: 'name=' + $this.val() + '&form_id=' + form_id,
                success: function (response, err) {
                    console.log(response);
                    console.log(err);
                    $nameField.val(response.full_name);
                    $emailField.val(response.email);
                    $phoneField.val(response.phone);
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });

        });

        $('body').on('change', '[data-from-user-forms-check]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var $button = $('[data-from-user-forms-button]');
            $button.trigger('click');
        });

        $('body').on('change', '[data-from-user-forms-select]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var $button = $('[data-from-user-forms-button]');
            $button.trigger('click');
        });

        $('body').on('click', '[data-from-user-forms-button]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var url = $this.attr('data-from-user-forms-url');
            var $userSelect = $('[data-from-user-forms-select]');
            var $userFormBlock = $('[data-form-user-forms-block]');
            var $checkbox = $('[data-from-user-forms-check]');

            var checked = 0;
            if ($checkbox.prop('checked') == true) {
                checked = 1
            }

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'html',
                data: 'name=' + $userSelect.val() + '&my_forms=' + checked,
                success: function (response, err) {
                    console.log(err);
                    $userFormBlock.empty();
                    $userFormBlock.append(response);
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('click', '[data-form-edit-id]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var url = $this.attr('data-form-edit-url');
            var form_id = $this.attr('data-form-edit-id');

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: 'form_id=' + form_id,
                success: function (response, err) {
                    console.log(err);
                    console.log(response);
                    if (response.status == 'ok') {
                        location.href = location.href;
                    }
                    else {
                        console.log('Error');
                        console.log(response.msg);
                    }
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('change', '[data-select-form-manager-changer]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var $form = $this.attr('data-select-form-manager-changer-id');
            var url = $this.attr('data-select-form-manager-changer-url');

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: 'form_id=' + $form + '&manager=' + $this.val(),
                success: function (response, err) {
                    console.log(err);
                    console.log(response);
                    if (response.status == 'ok') {
                    }
                    else {
                        console.log('Error');
                        console.log(response.msg);
                    }
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('click', '[data-login="button"]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var $form = $this.closest('[data-login="form"]');
            var url = $form.attr('data-login-url');

            $.ajax({
                url: url,
                type: 'POST',
                data: $form.serialize(),
                success: function (response, err) {
                    console.log(err);
                    if ($(response).find('#error').length > 0) {
                        $('[data-error-msg]').addClass('show');
                        $('#password').addClass('error');
                    } else {
                        window.top.location.href = location.origin + '/ru/form';
                    }
                },
                error: function(response, err) {
                    console.log(response);
                    console.log(err);
                    console.log('Fatal Error');
                }
            });
        });

        $('body').on('click', '[data-addnew-user-data]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var url = $this.attr('data-addnew-user-data-url');
            var $form = $this.closest('form');

            var isFormOk = true;

            if ($form.find('[name="name"]').val() != '' && !validateEmail($form.find('[name="name"]').val())) {
                $form.find('[name="name"]').addClass('error');
                isFormOk = false;
            } else {
                $form.find('[name="name"]').removeClass('error');
                isFormOk = true;
            }

            if ($form.find('[name="email"]').val() != '' && !validateEmail($form.find('[name="email"]').val())) {
                $form.find('[name="email"]').addClass('error');
                isFormOk = false;
            } else {
                $form.find('[name="email"]').removeClass('error');
                isFormOk = true;
            }
            if ($form.find('[name="phone"]').val() != '' && !validatePhone($form.find('[name="phone"]').val())) {
                $form.find('[name="phone"]').addClass('error');
                isFormOk = false;
            } else {
                $form.find('[name="phone"]').removeClass('error');
                isFormOk = true;
            }

            if (isFormOk) {
                $.ajax({
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    data: $form.serialize(),
                    success: function (response, err) {
                        console.log(response);
                        console.log(err);
                        if (response.status == 'ok') {
                            location.href = location.href;
                        }
                        else {
                            console.log('Error');
                            console.log(response.msg);
                            if (response.msg == 'no-login') {
                                $form.find('[name="name"]').addClass('error');
                            }
                            if (response.msg == 'no-pass') {
                                $form.find('[name="pass"]').addClass('pass');
                            }
                            if (response.msg == 'no-role') {
                                $form.find('[name="role"]').addClass('error');
                            }
                        }
                    },
                    error: function (response, err) {
                        console.log(response);
                        console.log(err);
                        console.log('Fatal Error');
                    }
                });
            }
        });

        $('body').on('click', '[data-change-user-pass]', function(e) {
            e.preventDefault();
            var $this = $(this);

            var url = $this.attr('data-change-user-pass-url');
            var isOkForm = true;
            var $form = $this.closest('form');

            if ($form.find('[name="password"]').val() == $form.find('[name="rep_password"]').val()) {
                isOkForm = true;
                $form.find('[name="password"]').removeClass('error');
                $form.find('[name="rep_password"]').removeClass('error');
            } else {
                $form.find('[name="password"]').addClass('error');
                $form.find('[name="rep_password"]').addClass('error');
                isOkForm = false;
            }

            if (isOkForm) {
                $.ajax({
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    data: $form.serialize(),
                    success: function (response, err) {
                        console.log(response);
                        console.log(err);
                        if (response.status == 'ok') {
                            location.href = location.href;
                        }
                        else {
                            console.log('Error');
                            console.log(response.msg);
                            $form.find('[name="old_password"]').addClass('error');
                        }
                    },
                    error: function (response, err) {
                        console.log(response);
                        console.log(err);
                        console.log('Fatal Error');
                    }
                });
            }
        });
    }

    function updatePage() {
        subformInit();
        updateTopCheckboxes();
        roomBlockInit();
        updateFormBlocks();
        disableCheckboxes();
        addSelectClone();
        sortFrooms();
    }

    function sortFrooms() {
        var $superBlock = $('[data-super-block-with-rooms]');

        $('[data-room-main-block]').each(function() {
            var $this = $(this);
            var id = parseInt($this.find('[data-order-id]').attr('data-order-id'));

            $this.attr('my-order-id', id);
        });

        $superBlock.find('[data-room-main-block]').sort(function (a, b) {
            return  $(a).attr('my-order-id') - $(b).attr('my-order-id');
        })
            .appendTo($superBlock);
    }

    function updateTopCheckboxes() {
        $('[data-room-block-top-checkbox]').each(function() {
            var $this = $(this);

            var thisBlockName = $this.attr('data-room-block-top-checkbox');
            var $thisBlock = $('[data-room-block="' + thisBlockName + '"]');
            var thisVal = $this.val();

            if ($this.prop('checked')) {
                revealRoomBlock($thisBlock);
                $('[data-block4-room-select]').find('option[value="' + thisVal + '"]').css('display', 'block');
            } else {
                hideRoomBlock($thisBlock);
                $('[data-block4-room-select]').find('option[value="' + thisVal + '"]').css('display', 'none');
            }
        });
    }

    function makeid()
    {
        return Math.random().toString(36).substring(2, 7);
    }

    function addSelectClone() {
        $('[data-select-clone]').each(function() {
            var $this = $(this);
            var thisId = $this.attr('data-select-clone');

            $this.change(function() {
                var thisValue = $this.val();

                $('[data-select-clone-block="' + thisId + '"]').each(function() {
                    if (!$(this).hasClass('clone')) {
                        $(this).remove();
                    }
                });

                for (var i = thisValue; i >= 1; i--) {
                    var $toClonBlock = $('[data-select-clone-block="' + thisId + '"].clone');
                    var $clonedBlock = $toClonBlock.clone();

                    $clonedBlock.removeClass('clone').attr('data-select-item', i);

                    var newIdForName = makeid();

                    $clonedBlock.find('input').each(function() {
                        var $clonedInput = $(this);

                        var clonedInputId = $clonedInput.attr('id');
                        var clonedInputName = $clonedInput.attr('name');
                        var newName = clonedInputName.slice(0, (clonedInputName.length-1));

                        newName = newName + newIdForName + ']';

                        $clonedInput.attr('name', newName);
                        $clonedInput.attr('id', clonedInputId + '_' + i);
                    });

                    $clonedBlock.find('select').each(function() {
                        var $clonedInput = $(this);

                        var clonedInputId = $clonedInput.attr('id');
                        var clonedInputName = $clonedInput.attr('name');
                        var newName = clonedInputName.slice(0, (clonedInputName.length-1));

                        newName = newName + newIdForName + ']';

                        $clonedInput.attr('name', newName);
                        $clonedInput.attr('id', clonedInputId + '_' + i);
                    });

                    $clonedBlock.find('label').each(function() {
                        var $clonedLabel = $(this);

                        var clonedLabelId = $clonedLabel.attr('for');
                        $clonedLabel.attr('for', clonedLabelId + '_' + i);
                    });

                    $clonedBlock.find('[data-select-clone-item="' + thisId + '"]').text(i);

                    $clonedBlock.insertAfter($toClonBlock);
                }
            });

        });
    }

    function disableCheckboxes() {
        $('[data-disable-checkbox]').each(function() {
            var $this = $(this);

            var $thisBlock = $this.closest('[data-disable-block]');
            var $thisInput = $thisBlock.find('[data-disable-input]');

            disableInputs($this, $thisInput);

            $this.change(function() {
                disableInputs($this, $thisInput);
            });
        });
    }

    function updateFormBlocks() {
        $('[data-form-block]').each(function() {
            var $this = $(this);
            var blockHeight = $this.height();
            $this.attr('data-form-block-height', blockHeight);
            if (!$this.hasClass('active')) {
                $this.height(0);
            }
        });
    }

    function findMaxByName(block) {
        var name = block.attr('name');
        var max = 0;

        $('[name="'+ name +'"]').each(function() {
            var $this = $(this);
            var val = parseInt($this.val());
            if (val > max) {
                max = val;
            }
        });
        return max;
    }

    function formValidationFunction(formId) {
        switch (formId) {
            case '0':
                return validateForm0(formId);
                break;
            default:
                return true;
        }

    }

    function validateForm0(formId) {
        var isValid = true;

        var form = $('[data-mega-form="' + formId + '"]');

        form.find('[data-validate-form="email"]').each(function() {
            var $this = $(this);
            if ($this.val() != '' && !validateEmail($this.val())) {
                isValid = false;
                $this.addClass('error');
            } else {
                isValid = true;
                $this.removeClass('error');
            }
        });

        form.find('[data-validate-form="phone"]').each(function() {
            var $this = $(this);
            if ($this.val() != '' && !validatePhone($this.val())) {
                isValid = false;
                $this.addClass('error');
            } else {
                isValid = true;
                $this.removeClass('error');
            }
        });

        return isValid;
    }

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function validatePhone(phone) {
        var phoneno = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
        return phoneno.test(phone);
    }

    function roomBlockInit() {
        $('[data-room-block]').each(function() {
            var $this = $(this);
            var thisAttr = $this.attr('data-room-block');

            var $subblock = $this.find('[data-room-subblock]');

            var subblockHeight = $subblock.height();
            $subblock.attr('data-room-subblock-height', subblockHeight);
            $subblock.height(0);

            var $mainCheckBox = $this.find('[data-room-block-main-checkbox]');

            if ($mainCheckBox.prop('checked')) {
                revealBlockSubblock($subblock);
                $this.addClass('active');
                $this.closest('[data-room-main-block]').addClass('active');
            } else {
                hideBlockSubblock($subblock);
                $this.removeClass('active');
                $this.closest('[data-room-main-block]').removeClass('active')
            }

            $mainCheckBox.click(function() {
                if ($mainCheckBox.prop('checked')) {
                    revealBlockSubblock($subblock);
                    $this.addClass('active');
                    $this.closest('[data-room-main-block]').addClass('active');
                } else {
                    hideBlockSubblock($subblock);
                    $this.removeClass('active');
                    $this.closest('[data-room-main-block]').removeClass('active')
                }
            });

            if ($('[data-room-block-top-checkbox="' + thisAttr + '"]').prop('checked') == false) {
                var thisBlockHeight = $this.height();
                $this.attr('data-room-block-height', thisBlockHeight);
                hideRoomBlock($this);
            }
        });
    }

    function subformInit() {
        $('[data-subform-block]').each(function() {
            var $this = $(this);
            var thisBlockHeight = $this.height();

            $this.attr('data-subform-block-height', thisBlockHeight);

            var $thisParent = $this.closest('[data-subform-parent]');
            var $thisCheckbox = $thisParent.find('[data-subform-checkbox]');

            if ($thisCheckbox.prop('checked')) {
                revealSubformBlock($this);
            } else {
                hideSubformBlock($this);
            }

            $thisCheckbox.change(function() {
                var $thisParentSubblock = $thisParent.closest('[data-height-calculation-subblock]');

                if ($thisCheckbox.prop('checked')) {
                    revealSubformBlock($this);
                } else {
                    hideSubformBlock($this);
                }

                if($thisParentSubblock) {
                    calculateHieghtOfmasterBlock($thisParentSubblock);
                }

            });

            if ($thisCheckbox.attr('type') == 'radio') {
                var thisCheckboxName = $thisCheckbox.attr('name');
                $('[name="' + thisCheckboxName + '"]').change(function() {
                    if (!$(this).attr('data-subform-checkbox')) {
                        hideSubformBlock($this);
                    }
                });
            }
        });
    }

    function calculateHieghtOfmasterBlock(block) {
        var $thisBlock = block;
        setTimeout(function() {
            var blockHight = $thisBlock.height();
            var $masterBlock = $thisBlock.closest('[data-height-calculation-master]');
            if ($masterBlock.height() < blockHight) {
                $masterBlock.height(blockHight);
            } else {
                $masterBlock.height('auto');
            }
        }, 350)
    }

    function specialShowHide(block) {
        var blockId = block.attr('data-special-checkbox-input');

        if (blockId) {
            if (block.prop('checked')) {
                $('[data-special-checkbox-field="' + blockId + '"]').removeClass('hide');
                $('[data-special-checkbox-field-unhide="' + blockId + '"]').addClass('disp-none');
            } else {
                $('[data-special-checkbox-field="' + blockId + '"]').addClass('hide');
                $('[data-special-checkbox-field-unhide="' + blockId + '"]').removeClass('disp-none');
            }
        } else {
            blockId = block.attr('data-special-checkbox-not-input');
            $('[data-special-checkbox-field="' + blockId + '"]').addClass('hide');
            $('[data-special-checkbox-field-unhide="' + blockId + '"]').removeClass('disp-none');
        }

    }

    function disableInputs(check, input) {
        if (check.prop('checked')) {
            input.each(function() {
                var $this = $(this);
                $this.attr('disabled', true);
                if (($this.attr('type') == 'checkbox' || $this.attr('type') == 'radio') && $this.prop('checked')) {
                    $this.prop('checked', false);
                }
                if ($this.attr('type') == 'text') {
                    $this.val('');
                }
            });
        } else {
            input.attr('disabled', false);
        }
    }

    function addActiveLeftMenu(id) {
        $('[data-button-goto-block="' + id +'"]').addClass('active');
    }

    function removeLeftMenuActive() {
        $('[data-button-goto-block]').each(function() {
            var $this = $(this);
            $this.removeClass('active');
        });
    }


    function hideBlock(blockId) {
        $('[data-form-block="' + blockId + '"]')
            .removeClass('active')
            .height(0);
    }

    function hideAllBlocks() {
        $('[data-form-block]').each(function() {
            var $this = $(this);
            var thiBlockId = $this.attr('data-form-block');
            hideBlock(thiBlockId);
        });
    }

    function revealBlock(blockId) {
        $('[data-form-block="' + blockId + '"]')
            .addClass('active')
            .height(getBlockHeight(blockId))
            .height('auto');
    }

    function goToBlock(blockId) {
        removeLeftMenuActive();
        addActiveLeftMenu(blockId);
        hideAllBlocks();
        revealBlock(blockId);
        var body = $("html, body");
        body.stop().animate({scrollTop:0}, '500', 'swing', function() {});
        updateTopCheckboxes();
    }

    function getBlockHeight(blockId) {
        var height = $('[data-form-block="' + blockId + '"]').attr('data-form-block-height');
        return height;
    }

    function hideBlockSubblock(subblock) {
        subblock.height(0);
    }

    function revealBlockSubblock(subblock) {
        var subblockHeight = subblock.attr('data-room-subblock-height');
        subblock.height(subblockHeight);
        subblock.height('auto');
    }

    function hideRoomBlock(block) {
        block.height(0);
        block.addClass('hide');
        block.closest('[data-room-main-block]').removeClass('show');
    }

    function revealRoomBlock(block) {
        var roomBlockHeight = block.attr('data-room-block-height');
        block.height(roomBlockHeight);

        if (!block.closest('[data-room-main-block]').hasClass('show')) {
            if ($('[data-room-main-block].show').first().length > 0) {
                block.closest('[data-room-main-block]').insertAfter($('[data-room-main-block].show').last());
            }

            block.closest('[data-room-main-block]').addClass('show');
        }

        block.removeClass('hide');
        block.height('auto');
    }

    function hideSubformBlock(block) {
        updateSubformBlockHeight(block);
        var blockHeight = block.attr('data-subform-block-height');
        block.height(blockHeight);
        block.height(0);
        block.addClass('hide');

        block.find('input').each(function() {
            var $input = $(this);
            if ($input.attr('name') == 'new_room_name') {

            } else {
                if (typeof $input.attr('data-dont-clear') === "undefined" || $input.attr('data-dont-clear') === false) {
                    if ($input.attr('type') == 'checkbox' || $input.attr('type') == 'radio') {
                        if (typeof $input.attr('data-disable-checkbox') === "undefined" || $input.attr('data-disable-checkbox') === false) {
                            $input.prop('checked', false);
                        }
                    } else {
                        $input.val('');
                    }
                }
            }
        });

        block.find('textarea').each(function() {
            var $input = $(this);
            if (typeof $input.attr('data-dont-clear') === "undefined" || $input.attr('data-dont-clear') === false) {
                $input.val('');
            }
        });

        block.find('select').each(function() {
            var $input = $(this);
            if (typeof $input.attr('data-dont-clear') === "undefined" || $input.attr('data-dont-clear') === false) {
                $input.find('option:first').prop('selected', true);
            }
            $input.change();
        });

        block.find('[data-uploaded-files-items]').each(function() {
            var $fileBlock = $(this);
            if (!$fileBlock.hasClass('clone')) {
                $fileBlock.remove();
            }
        });
    }

    function revealSubformBlock(block) {
        updateSubformBlockHeight(block);
        var blockHeight = block.attr('data-subform-block-height');
        block.height(blockHeight);
        setTimeout(function() {
            block.removeClass('hide');
            block.height('auto');
        }, 250);
    }

    function updateSubformBlockHeight(block) {
        var blockHeight = block.height();
        block.attr('data-subform-block-height', blockHeight);
    }

    $('[data-data="1"]').click(function() {
        saveBlock1();
    });
    $('[data-data="2"]').click(function() {
        saveBlock2();
    });
    $('[data-data="3"]').click(function() {
        saveBlock3();
    });
    $('[data-data="4"]').click(function() {
        saveBlock4();
    });
    $('[data-data="5"]').click(function() {
        saveAllRooms();
    });

    function saveCurrentForm(id) {
        switch (id) {
            case 0:
                saveBlock1();
                break;
            case 1:
                saveBlock2();
                saveAllRooms();
                break;
            case 2:
                saveBlock3();
                break;
            case 3:
                saveBlock4();
                break;
        }
    }

    function saveAll() {
        saveBlock1();
        saveBlock2();
        saveBlock3();
        saveBlock4();
        saveAllRooms();
    }

    function renameAllFileNames(data) {
        for (var i = 0; i < data.length; i++) {
            var img = data[i];
            $('[name="file_filename[]"]').each(function() {
                console.log(img);
                var $this = $(this);
                if ($this.val() == img.old_filename) {
                    $this.val(img.new_filename);
                }
            });
            $('[name="file_plan_filename[]"]').each(function() {
                console.log(img);
                var $this = $(this);
                if ($this.val() == img.old_filename) {
                    $this.val(img.new_filename);
                }
            });
            $('[name="file_image_inspiration_filename[]"]').each(function() {
                console.log(img);
                var $this = $(this);
                if ($this.val() == img.old_filename) {
                    $this.val(img.new_filename);
                }
            });
        }

    }

    function fileUpload() {
        var $block21Form = $('[data-mega-form="1-1"]');

        var url = $block21Form.attr('data-mega-form-url');
        var $filesLocationInput = $('[data-files-location-dir]');

        var formId = getNewFormId();
        $.ajax({
            url: url + '?&form_id=' + formId,
            type: 'POST',
            data: FILES,
            dataType: 'json',
            cache: false,
            processData: false,
            contentType: false,
            success: function (response, err) {
                console.log(response);
                console.log(err);
                if (response.status == 'ok') {
                    $filesLocationInput.val(response.locations);
                    renameAllFileNames(response.locations);
                }
                else {
                    console.log('Error');

                }
            },
            error: function(response, err) {
                console.log(response);
                console.log(err);
                console.log('Fatal Error');
            }
        });
    }

    function saveBlock4() {
        var $block4Form = $('[data-mega-form="3"]');

        var url = $block4Form.attr('data-mega-form-url');

        var formId = getNewFormId();
        $.ajax({
            url: url,
            type: 'POST',
            data: 'form_id=' + formId + '&' + $block4Form.serialize(),
            dataType: 'json',
            success: function (response, err) {
                console.log(response);
                console.log(err);
                if (response.status == 'ok') {

                }
                else {
                    console.log('Error');

                }
            },
            error: function(response, err) {
                console.log(response);
                console.log(err);
                console.log('Fatal Error');
            }
        });
    }

    function saveBlock3() {

        var $block3Form = $('[data-mega-form="2"]');

        var url = $block3Form.attr('data-mega-form-url');

        var formId = getNewFormId();
        $.ajax({
            url: url,
            type: 'POST',
            data: 'form_id=' + formId + '&' + $block3Form.serialize(),
            dataType: 'json',
            success: function (response, err) {
                console.log(response);
                console.log(err);
                if (response.status == 'ok') {

                }
                else {
                    console.log('Error');

                }
            },
            error: function(response, err) {
                console.log(response);
                console.log(err);
                console.log('Fatal Error');
            }
        });
    }

    function saveBlock2() {

        var $block21Form = $('[data-mega-form="1-1"]');
        var $block22Form = $('[data-mega-form="1-2"]');
        var $block23Form = $('[data-mega-form="1-3"]');

        var url = $block22Form.attr('data-mega-form-url');

        var formId = getNewFormId();
        $.ajax({
            url: url,
            type: 'POST',
            data: 'form_id=' + formId + '&' + $block21Form.serialize() + '&' + $block22Form.serialize() + '&' + $block23Form.serialize(),
            dataType: 'json',
            success: function (response, err) {
                console.log(response);
                console.log(err);
                if (response.status == 'ok') {

                }
                else {
                    console.log('Error');

                }
            },
            error: function(response, err) {
                console.log(response);
                console.log(err);
                console.log('Fatal Error');
            }
        });
    }

    function saveBlock1() {
        var $block1Form = $('[data-mega-form="0"]');

        var url = $block1Form.attr('data-mega-form-url');

        var formId = getNewFormId();
        $.ajax({
            url: url,
            type: 'POST',
            data: 'form_id=' + formId + '&' + $block1Form.serialize(),
            dataType: 'json',
            success: function (response, err) {
                console.log(response);
                console.log(err);
                if (response.status == 'ok') {

                }
                else {
                    console.log('Error');

                }
            },
            error: function(response, err) {
                console.log(response);
                console.log(err);
                console.log('Fatal Error');
            }
        });
    }

    function saveAllRooms() {
        var formId = getNewFormId();
        $.ajax({
            url: getDeleteRoomsURL(),
            type: 'POST',
            data: 'form_id=' + formId,
            dataType: 'json',
            success: function (response, err) {
                console.log(response);
                console.log(err);
                if (response.status == 'ok') {
                    saveRoomsAfterDelete();
                }
                else {
                    console.log('Error');
                }
            },
            error: function(response, err) {
                console.log(response);
                console.log(err);
                console.log('Fatal Error');
            }
        });
    }

    function saveRoomsAfterDelete() {

        $('[data-room-form]').each(function() {
            var $thisForm = $(this);
            var room_type = $thisForm.attr('data-room-form');

            var room_order_id = $thisForm.attr('data-order-id');

            if ($('[data-room-block-top-checkbox="' + room_type + '"]').prop('checked')) {

                var formId = getNewFormId();
                var url = $thisForm.attr('data-mega-form-url');

                var thisRoomTypeCount = $('[data-room-form="' + room_type + '"]').length;
                var moreRooms = '';
                if (thisRoomTypeCount > 1) {
                    moreRooms = '&more_rooms=' + thisRoomTypeCount;
                }

                $.ajax({
                    url: url,
                    type: 'POST',
                    data: 'form_id=' + formId + '&room_type=' + room_type + moreRooms + '&order_id=' + room_order_id + '&' + $thisForm.serialize(),
                    dataType: 'json',
                    success: function (response, err) {
                        console.log(response);
                        console.log(err);
                        if (response.status == 'ok') {

                        }
                        else {
                            console.log('Error');

                        }
                    },
                    error: function(response, err) {
                        console.log(response);
                        console.log(err);
                        console.log('Fatal Error');
                    }
                });
            }
        });

    }

    function getNewFormId() {
        var newFormId = $('#new_form_id').val();
        return newFormId;
    }

    function getDeleteRoomsURL() {
        var url = $('#delete_rooms_url').val();
        return url;
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    
    
    
    
    
    
    
    
    
    
    
    
    
    
    // $('#start_date').pickadate({
    //     formatSubmit: 'yyyy-mm-dd',
    //     selectMonths: true,
    //     onStart: function ()
    //     {
    //         var date = new Date();
    //         this.set('select', [date.getFullYear(), date.getMonth(), date.getDate()]);
    //     }
    // });
    // $('#end_date').pickadate({
    //     formatSubmit: 'yyyy-mm-dd',
    //     selectMonths: true,
    //     onStart: function ()
    //     {
    //         var date = new Date();
    //         this.set('select', [date.getFullYear(), date.getMonth(), date.getDate() + 1]);
    //         var item = $('#end_date');
    //         refreshCalc(item);
    //     }
    // });
    //
    // $('[data-login="button"]').click(function(e) {
    //     e.preventDefault();
    //     $('[data-login="loader"]').addClass('active');
    //     $('[data-login="form"]').submit();
    // });
    //
    // $('[data-checkin="form"]').on('click', '[data-checkin="start"]', function(e) {
    //     e.preventDefault();
    //     $('[data-checkin="loader" ]').addClass('active');
    //     var url = $('[data-url-start]').attr('data-url-start');
    //     $.ajax({
    //         url: url,
    //         type: 'POST',
    //         dataType: 'json',
    //         success: function (response) {
    //             if (response.status == 'ok') {
    //                 $('[data-checkin="start"]').attr('data-checkin', 'stop').removeClass('waves-green').addClass('red').text('Check Out');
    //                 $('[data-checkin="loader" ]').removeClass('active');
    //                 refreshTable();
    //             }
    //             else {
    //                 console.log('Error');
    //                 $('#errors').css('display', 'block');
    //                 reloadTimeout();
    //             }
    //         },
    //         error: function() {
    //             console.log('Fatal Error');
    //             $('[data-checkin="loader" ]').removeClass('active');
    //             $('#errors').css('display', 'block');
    //             reloadTimeout();
    //         }
    //     });
    // })
    // .on('click', '[data-checkin="stop"]', function(e) {
    //     e.preventDefault();
    //     $('[data-checkin="loader" ]').addClass('active');
    //     var url = $('[data-url-stop]').attr('data-url-stop');
    //     $.ajax({
    //         url: url,
    //         type: 'POST',
    //         dataType: 'json',
    //         success: function (response) {
    //             if (response.status == 'ok') {
    //                 $('[data-checkin="stop"]').attr('data-checkin', 'start').removeClass('red').addClass('waves-green').text('Check In');
    //                 $('[data-checkin="loader" ]').removeClass('active');
    //                 refreshTable();
    //             }
    //             else {
    //                 console.log('Error');
    //                 $('#errors').css('display', 'block');
    //                 reloadTimeout();
    //             }
    //         },
    //         error: function() {
    //             console.log('Fatal Error');
    //             $('[data-checkin="loader" ]').removeClass('active');
    //             $('#errors').css('display', 'block');
    //             reloadTimeout();
    //         }
    //     });
    // });
    //
    // $('[data-checkin="date-input"]').change(function() {
    //     refreshTable();
    // });
    //
    // $('[data-export="date-input"]').change(function() {
    //     refreshExportTable();
    // });
    //
    // $('[data-export="button"]').click(function() {
    //     var url = $('[data-export-url]').attr('data-export-url');
    //     var xhr = new XMLHttpRequest();
    //     xhr.open('POST', url, true);
    //     xhr.responseType = 'arraybuffer';
    //     xhr.onload = function () {
    //         if (this.status === 200) {
    //             var filename = "";
    //             var disposition = xhr.getResponseHeader('Content-Disposition');
    //             if (disposition && disposition.indexOf('attachment') !== -1) {
    //                 var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    //                 var matches = filenameRegex.exec(disposition);
    //                 if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
    //             }
    //             var type = xhr.getResponseHeader('Content-Type');
    //
    //             var blob = new Blob([this.response], { type: type });
    //             if (typeof window.navigator.msSaveBlob !== 'undefined') {
    //                 window.navigator.msSaveBlob(blob, filename);
    //             } else {
    //                 var URL = window.URL || window.webkitURL;
    //                 var downloadUrl = URL.createObjectURL(blob);
    //
    //                 if (filename) {
    //                     var a = document.createElement("a");
    //                     if (typeof a.download === 'undefined') {
    //                         window.location = downloadUrl;
    //                     } else {
    //                         a.href = downloadUrl;
    //                         a.download = filename;
    //                         document.body.appendChild(a);
    //                         a.click();
    //                     }
    //                 } else {
    //                     window.location = downloadUrl;
    //                 }
    //
    //                 setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100);
    //             }
    //         }
    //     };
    //     xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    //     xhr.send($('[data-export="filterForm"]').serialize());
    //
    // });
    //
    // function refreshExportTable() {
    //     var url = $('[data-export="table"]').attr('data-url');
    //     $.ajax({
    //         url: url,
    //         type: 'POST',
    //         data: $('[data-export="filterForm"]').serialize(),
    //         dataType: 'html',
    //         success: function (response) {
    //             $('[data-export="table"] tbody').empty().append(response);
    //         },
    //         error: function() {
    //             console.log('Fatal Error');
    //             $('#errors').css('display', 'block');
    //             reloadTimeout();
    //         }
    //     });
    // }
    //
    //
    // function refreshTable() {
    //     var url = $('[data-checkin="table"]').attr('data-url');
    //     $.ajax({
    //         url: url,
    //         type: 'POST',
    //         data: $('[data-checkin="filterForm"]').serialize(),
    //         dataType: 'html',
    //         success: function (response) {
    //             $('[data-checkin="table"] tbody').empty().append(response);
    //         },
    //         error: function() {
    //             console.log('Fatal Error');
    //             $('#errors').css('display', 'block');
    //             reloadTimeout();
    //         }
    //     });
    // }
    //
    // function refreshCalc(item) {
    //     var attr = item.attr('data-checkin');
    //     if (typeof attr != typeof undefined && attr !== false) {
    //         refreshTable();
    //     }
    //     else {
    //         refreshExportTable();
    //     }
    // }
    //
    // function reloadTimeout() {
    //     setTimeout(function() {
    //         location.reload();
    //     }, 3000);
    // }

});